.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 30%;
  width: 65%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
.anlyseContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.5; /* Adjust the opacity as needed */
  max-height: 80vh;
}

.iotBtn {
  border: none;
  padding: 8px;
  border-radius: 5px;
  background-color: #007fc7;
  color: whitesmoke;
  width: fit-content;
  margin-bottom: 5px;
}

.neumorphicCard {
  border-radius: 8px;
  padding: 30px;
}

.checkIcon {
  margin-right: 10px;
  color: #28a745;
}


@media screen and (max-width: 768px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
  .textContainer > h6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 425px) {
  .textContainer {
    top: 25%;
    width: 95%;
  }
  .textContainer > h6 {
    font-size: 14px;
  }
  .textContainer > h1 {
    font-size: 30px;
  }
  .anlyseContainer {
    padding-top: 20px;
  }
  .anlyseImgContainer {
    padding-top: 20px;
  }
}

@media screen and (max-width: 425px) {
  .textContainer {
    top: 20%;
  }
}
