.casestudyContainer {
  background: #a9a9a9;
  min-height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 425px) {
  .orbioContent > h3 {
    font-size: 20px;
  }
  .orbioContent > p {
    font-size: 14px;
  }
}

.titleCarouselAnimation {
  animation: tracking-in-expand-fwd-bottom 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
/* scrollbar */
.customCarouselScrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0); /* For Firefox */
}

.customCarouselScrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 0);
}

.customCarouselScrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* button hover efect section */
.cta {
  position: relative;
  margin: auto;
  padding: 12px 8px;
  transition: all 0.2s ease;
}
.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: transparent;
  width: 43px;
  height: 43px;
  transition: all 0.3s ease;
  border: 1px solid black;
}
.cta span {
  position: relative;
  font-size: 20px;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 7px;
  font-weight: bolder;
}
.rightarrow {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #111;
  stroke-width: 2;
  color: black;
}
.cta:hover:before {
  width: 100%;
  background: transparent;
}
.cta:hover .rightarrow {
  transform: translateX(0);
}
.cta:active {
  transform: scale(0.96);
}
/* carouselContainer */
.carouselContainer {
  position: relative;
  top: 40%;
  height: 100%;
  font-weight: bold;
  overflow: auto;
}
.singleItemCarouselImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.singlCarouselImg {
  width: 100%;
  height: 70%;
  object-fit: contain;
}
.singlCarouselTitle {
  font-size: 20px;
}
.singlCarouselHeading {
  font-size: 36px;
  font-weight: bolder;
}
.singlCarouselDesc {
  line-height: 1.6rem;
  font-weight: bold;
}
.singleCarouselCircleSection {
  cursor: pointer;
}
.singlCarouselRightArrow {
  font-size: 53px;
  margin-left: 10px;
}
.singleItemimgContainer {
  position: relative;
  top: 40%;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

/* resonsive */
@media screen and (max-width: 768px) {
  .singleItemimgContainer {
    position: relative;
    top: 0%;
    padding-top: 30px;
    padding-bottom: 90px;
  }
  .singlCarouselImg {
    margin-left: -23px;
  }
}
@media screen and (max-width: 767px) {
  .carouselContainer {
    position: relative;
    top: 10% !important;
    height: 100% !important;
    overflow: auto;
  }
  .singlCarouselTitle {
    font-size: 18px;
  }
  .singlCarouselHeading {
    font-size: 25px;
  }
  .singlCarouselDesc {
    line-height: none;
    font-weight: bold;
    font-size: 14px;
  }
  .singlCarouselRightArrow {
    font-size: 42px;
  }
  .cta:before {
    width: 32px;
    height: 32px;
  }
  .cta span {
    font-size: 15px;
  }
  .cta {
    padding: 6px 0px;
  }
}
