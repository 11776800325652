.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 35%;
  width: 45%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}

.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.3;
  max-height: 80vh;
}
.imgContent {
  color: whitesmoke;
  width: 60%;
  padding-left: 2em;
  display: flex;
  flex-wrap: wrap;
}
.imgDiv {
  background-image: url("/public/assets/presentation1.webP");
  background-size: cover;
  background-position: center;
  height: 60vh;
  position: relative;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
  .textContainer > h6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 14px;
  }
  .textContainer > h1 {
    font-size: 2em;
  }
  .anlyseContainer {
    padding-top: 20px;
  }
  .anlyseImgContainer {
    padding-top: 20px;
  }
  .imgContent {
    width: 100%;
    padding-left: 1em;
  }
  .imgDiv {
    height: 40vh;
  }
  .imgContent {
    font-size: 1.1em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1.7);
  }
}
@media screen and (max-width: 375px) {
  .imgoverlay {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0.2;
    max-height: 100%;
  }
  .textContainer {
    top: 20%;
  }
  .imgDiv {
    height: 30vh;
  }
}
