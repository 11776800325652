.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 30%;
  width: 50%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
.anlyseContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}
.strategicCol {
  border-right: 1px solid white;
  padding-right: 40px;
  display: flex;
  align-items: center;
}

.strategicCol > h5 {
  font-size: 18px;
  margin-bottom: 0px;
  color: #007fc7;
}

.strategicRow {
  background: #181717 !important;
  /* border: 1px solid white !important; */
  border-radius: 8px !important;
  padding: 20px !important;
  display: flex !important;
  justify-content: center !important;
  margin: 1em !important;
}

/* card hover effect */
.cardContainer {
  cursor: pointer;
}

.card {
  position: relative !important;
  border: none !important;
  height: 250px !important;
}

.cardContainer {
  overflow: hidden;
}
.cardTitle {
  color: #0baad8;
  text-align: left;
}
.cardContainer .cardSection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(63, 62, 62, 0.6); /* Semi-transparent background */
  backdrop-filter: blur(0.2px); /* Apply a smaller blur */
  color: whitesmoke;
  border-radius: 8px;
  height: 100%;
  padding: 20px;
  transform: translateY(65%);
  transition: transform 0.3s ease;
}
.cardImg {
  filter: grayscale(100%) !important;
  height: 250px !important;
  width: 100% !important;
  object-fit: cover !important;
}
.cardContainer:hover .cardSection {
  transform: translateY(0);
}

.cardSection > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
  .textContainer > h6 {
    font-size: 16px;
  }
  .strategicCol {
    border-right: none;
    justify-content: center;
  }
  .strategicCol > h5 {
    text-align: center;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 14px;
  }
  .textContainer > h1 {
    font-size: 35px;
  }
  .anlyseContainer {
    padding-top: 20px;
  }
  .anlyseImgContainer {
    padding-top: 20px;
  }
  .textContainer {
    top: 20%;
    width: 90%;
  }
  .cardSection > div > p {
    font-size: 14px;
    overflow: scroll;
  }
  .cardTitle {
    font-size: 18px !important;
  }
}
