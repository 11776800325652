@font-face {
  font-family: "Imported";
  src: url("../src/fonts/D-DIN.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Imported;
}

.textJustify {
  text-align: justify;
}

.overViewTitle {
  font-size: 26px;
  color: #007fc7 !important;
  display: flex;
  align-items: center;
}
