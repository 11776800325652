.inputStyle {
  border-bottom: 1px solid #c6c9ca;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  width: 100%;
  height: 40px;
}
.toggleBtn {
  color: #017fc6;
  font-weight: bold;
  cursor: pointer;
}
.contactForm {
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: 15px;
}

.additionalFields {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.additionalFields.show {
  opacity: 1;
  max-height: fit-content; /* Adjust this height based on your content */
}
.btnContainer {
  text-align: start;
  margin-top: 14px;
}
.continueBtn {
  /* background: #017fc6; */
  color: white !important;
  border-radius: 9px !important;
  border: none !important;
  padding: 10px !important;
  width: 145px !important;
  height: 47px !important;
}
.continueBtn:hover .arrowIcon {
  transform: translateX(5px); /* Move the arrow to the right on hover */
}
.arrowIcon {
  transition: transform 0.3s ease; /* Apply transition on transform property */
}

.recaptchaContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.recaptchaContainer > div {
  width: 304px; /* Adjust the width based on your layout */
}
