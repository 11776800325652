.textContainer {
  position: absolute;
  margin-left: 45px;
  top: 35%;
  width: 45%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
@media screen and (max-width: 768px) {
  .textContainer {
    width: 70%;
    top: 27%;
  }
}
