.img {
  object-fit: cover;
  width: 100%;
  height: 60vh;
  filter: grayscale(100%);
}

.carouselShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.carouselCap {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  text-align: center !important;
  color: white !important;
  z-index: 2 !important;
  width: 70% !important;
}

@media (max-width: 768px) {
  .carouselCap {
    top: 40% !important;
  }
}
@media (max-width: 425px) {
  .carouselCap {
    top: 35% !important;
  }
  .carouselCap > h3 {
    font-size: 20px;
  }
  .carouselCap > p {
    font-size: 15px;
  }
}
@media (max-width: 320px) {
  .carouselCap {
    top: 35% !important;
  }
  .carouselCap > h3 {
    font-size: 18px;
  }
  .carouselCap > p {
    font-size: 12px;
  }
}

@media (max-width: 240px) {
  .carouselCap {
    top: 30% !important;
  }
}
