.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 35%;
  width: 45%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
.btn {
  background-color: #f5af32 !important;
  border: #f5af32 !important;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}
.singleCarouselCircleSection {
  cursor: pointer;
}
.singlCarouselRightArrow {
  font-size: 53px;
  margin-left: 10px;
}
/* button hover efect section */
.cta {
  position: relative;
  margin: auto;
  padding: 12px 8px;
  transition: all 0.2s ease;
}
.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: transparent;
  width: 43px;
  height: 43px;
  transition: all 0.3s ease;
  border: 1px solid black;
}
.cta span {
  position: relative;
  font-size: 20px;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 7px;
  font-weight: bolder;
}
.cta:hover:before {
  width: 100%;
  background: transparent;
}
.cta:hover .rightarrow {
  transform: translateX(0);
}
.cta:active {
  transform: scale(0.96);
}
.contactusBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  padding: 20px;
}
.chooseSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.chooseSection > h6 {
  font-weight: bold;
}
.chooseSection > p {
  font-size: 15px;
}
.caseStudyContainer {
  background: #a9a9a9;
  min-height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .service1 {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .service2 {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .service3 {
    border-bottom: 1px solid #ccc;
  }
  .service4 {
    border-right: 1px solid #ccc;
  }
  .service5 {
    border-right: 1px solid #ccc;
  }
}
@media screen and (max-width: 767px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
  .textContainer > h6 {
    font-size: 16px;
  }
  .service1 {
    border: 1px solid #ccc;
  }
  .service2,
  .service3,
  .service4,
  .service5,
  .service6 {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .singlCarouselRightArrow {
    font-size: 42px;
  }
  .cta:before {
    width: 32px;
    height: 32px;
  }
  .cta span {
    font-size: 15px;
  }
  .cta {
    padding: 6px 0px;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 14px;
  }
  .textContainer > h1 {
    font-size: 35px;
  }
  .contactheading {
    font-size: 15px;
  }
  .btn {
    font-size: 13px;
  }
}
