.homeConatiner {
  background-size: cover;
  background-position: center;
  height: 100vh;
  top: 0;
  color: white;
}
.btn {
  border: 2px solid white !important;
  width: 142px;
  height: 43px;
  color: white !important;
  border-radius: 38px !important;
  background: transparent !important;
  background-color: transparent !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover {
  color: #787c7f !important;
  border: 2px solid #787c7f !important;
}
.technology {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 40%;
  position: absolute;
  top: 5%;
  left: 6%;
  flex-wrap: wrap;
}
.technology > h1 {
  color: white;
  font-weight: bold;
}
.technology > h2 {
  color: white;
  font-size: 18px;
}

.technology > h4 {
  color: white;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 15px;
}

.modalTitle {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333;
}

.modalIcon {
  margin-right: 10px;
  color: green;
}

@media (max-width: 375px) {
  .technology > h1 {
    font-size: 22px;
  }
  .technology > h4 {
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .technology {
    left: 0;
    width: 100%;
    padding: 12px;
  }
}
@media (max-width: 325px) {
  .technology > h1 {
    font-size: 22px;
  }
  .technology > h4 {
    font-size: 18px;
  }
}
@media (max-width: 319px) {
  .btn {
    width: auto;
    height: auto;
    font-size: calc(5vw) !important;
  }
}
