.orbiowaycontainer {
  color: #fff;
  height: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("/public/assets/orbioway.webP");
}
