
.textContainer {
  position: absolute;
  top: 35%;
  width: 100%;
}
.textContainerheading {
  font-weight: bolder;
  font-size: 50px;
  color: white;
}
.mainRow {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.spanLeftContent {
  font-size: 22px;
  color: white;
  font-weight: bold;
}
.spanRightContent {
  font-size: 20px;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .textContainer {
    top: 26%;
  }
  .mainRow {
    gap: 20px;
  }
  .spanLeftContent {
    font-size: 20px;
  }
  .spanRightContent {
    font-size: 17px;
  }
}
@media screen and (max-width: 425px) {
  .textContainerheading {
    font-size: 40px;
  }
  .spanLeftContent {
    font-size: 18px;
  }
  .spanRightContent {
    font-size: 16px;
  }
}
