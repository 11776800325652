.videoPlayerContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.circlesContainer {
  position: relative;
  width: 330px;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoplayerOuterCircle {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 2px solid #0581c8;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.videoplayerInnerCircle {
  width: 330px;
  height: 330px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ccc;
  position: relative;
  z-index: 2;
}

/* responsive section */
@media screen and (max-width: 768px) {
  .videoPlayerContainer {
    justify-content: center;
  }
  .circlesContainer {
    width: 280px;
    height: 280px;
  }
  .videoplayerOuterCircle {
    width: 300px;
    height: 300px;
  }
  .videoplayerInnerCircle {
    width: 280px;
    height: 280px;
  }
  .videoPlayerContainer {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .videoPlayerContainer {
    justify-content: center;
    align-items: center;
  }
  .circlesContainer {
    width: 280px;
    height: 280px;
  }
  .videoplayerOuterCircle {
    width: 300px;
    height: 300px;
  }
  .videoplayerInnerCircle {
    width: 280px;
    height: 280px;
  }
}

@media screen and (max-width: 319px) {
  .circlesContainer {
    width: calc(40vw);
    height: calc(40vw);
  }
  .videoplayerOuterCircle {
    width: calc(50vw);
    height: calc(50vw);
  }
  .videoplayerInnerCircle {
    width: calc(40vw);
    height: calc(40vw);
  }
  .wave-effect {
    height: calc(20vw) !important;
    width: calc(20vw) !important;
  }
}

@keyframes waveAnimation {
  0% {
    box-shadow: 0 0 0 0px rgba(33, 138, 230, 0.6);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 2, 2, 0);
  }
  100% {
    box-shadow: 0 0 0 40px rgba(244, 4, 4, 0);
  }
}

.wave-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #007fc7;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: #007fc7;
  animation: waveAnimation 2s infinite ease-in-out;
  cursor: pointer;
}
