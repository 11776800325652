.textContainer {
  position: absolute;
  margin-left: 45px;
  top: 35%;
  width: 45%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
.heading {
  font-size: 35px;
  font-weight: bold;
  color: #1b1d21;
  font-weight: bold;
  padding-top: 10px;
}
.desc {
  font-size: 16px;
  color: #6d6d6d;
  line-height: 1.7em;
}
.lists {
  font-size: 16px;
  color: #6d6d6d;
  line-height: 1.7em;
}
.imgcontainer {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.inpfield {
  padding: 0 16px 0 12px;
  height: 45px;
  border: 1px solid #dddbda;
  border-radius: 4px !important;
  background-color: #fff;
  color: rgb(8, 7, 7);
}
.inpfield option {
  padding: 10px !important;
  height: 12px;
}
.inpfield:focus {
  border-color: none !important;
  box-shadow: none !important;
}
.jobActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
.tableData {
  padding: 20px !important;
}
.desig {
  color: #007fc7;
  font-weight: bold;
  cursor: pointer;
}
.moredetails {
  font-size: 14px;
  color: #007fc7;
  cursor: pointer;
}
.locations {
  font-size: 14px;
  color: #6d6d6d;
}
.filterrow {
  display: flex;
  justify-content: flex-end;
}
.cultureImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imgLocContainer {
  display: none;
}
.cultureImg:hover + .imgLocContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  color: white;
  width: 90%;
  left: 5%;
  text-align: center;
  transition: opacity 0.3s ease;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}

@media screen and (max-width: 768px) {
  .textContainer {
    width: 70%;
  }
  .filterrow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .jobActions {
    justify-content: space-between;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 16px;
  }
  .textContainer > h1 {
    font-size: 35px;
  }
  .heading {
    font-size: 30px;
  }
  .lists,
  .desc {
    font-size: 14px;
  }
  .tableData {
    padding: 10px !important;
  }
  .desig {
    font-size: 17px !important;
  }
  .moredetails,
  .locations {
    font-size: 13px;
  }
}
