.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 35%;
  width: 60%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
.anlyseContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
  .textContainer > h6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 14px;
  }
  .textContainer > h1 {
    font-size: 35px;
  }
  .anlyseContainer {
    padding-top: 20px;
  }
  .anlyseImgContainer {
    padding-top: 20px;
  }
  .textContainer {
    top: 30%;
    width: 95%;
  }
}

@media screen and (max-width: 320px) {
  .textContainer {
    top: 25%;
  }
  .textContainer > h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 250px) {
  .textContainer {
    top: 20%;
  }
  .textContainer > h1 {
    font-size: 30px;
  }
}
