.textContainer {
    position: absolute;
    margin-left: 2%;
    top: 35%;
    width: 45%;
  }
  .textContainer > h1 {
    font-weight: bolder;
    font-size: 40px;
    color: whitesmoke;
  }
  .heading {
    font-weight: bold;
    margin-top: 30px;
    font-size: 30px;
  }
  .desc {
    line-height: 30px;
    color: #6d6d6d;
    font-size: 16px;
  }
  .btn {
    background: none !important;
    color: black !important;
    border: 1px solid black !important;
    padding: 1px !important;
  }
  .backArrow {
    border: 1px solid rgb(27, 29, 33) !important;
    padding: 0px 12px 0px 12px !important;
  }
  .backArrow:active {
    position: relative;
    right: 2px;
  }
  .sublinks {
    text-decoration: none;
    color: #6d6d6d;
    margin-left: 12px;
  }
  @media screen and (max-width: 768px) {
    .textContainer {
      width: 70%;
    }
    .textContainer > h1 {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 375px) {
    .btn {
      font-size: 12px !important;
    }
    .heading {
      font-size: 25px;
    }
    .desc {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 320px) {
    .textContainer > h1 {
      font-size: 30px;
    }
  }
  