.containerlogo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.hello {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  z-index: 1;
}

.circle {
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  opacity: 0;
  animation: waves 2.5s linear infinite;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.delay1 {
  animation-delay: 0.1s;
}

.delay2 {
  animation-delay: 0.7s;
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}
