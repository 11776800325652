.img {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
  cursor: pointer;
}
.btn {
  color: black !important;
  background: none !important;
  border-color: black !important;
  padding: 2px !important;
  font-size: 13px !important;
}
.heading {
  font-weight: bold;
  margin-top: 14px;
}
.readmore {
  font-weight: bold;
  cursor: pointer;
  color: black;
}

@media screen and (max-width: 768px) {
  .desc {
    margin-top: 18px;
  }
}
