.textContainer {
  position: relative;
  margin-left: 45px;
  width: 45%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 40px;
  color: whitesmoke;
  word-wrap: break-word;
}
.jobContainer > p,
ul > li {
  color: #6d6d6d;
}
.jobContainer > p span {
  padding-left: 5px;
}
.inpfield {
  padding: 0 16px 0 12px;
  height: 40px;
  border: 1px solid #dddbda;
  border-radius: 4px !important;
  background-color: #fff;
  color: rgb(8, 7, 7);
}
.inpfield:focus {
  border-color: none !important;
  box-shadow: none !important;
}
.formLabel {
  color: #6d6d6d;
}
.btnContainer {
  border: 1px solid #03b7f0;
  border-radius: 4px;
  padding: 3px;
  color: #03b7f0;
}
.backArrow {
  border: 1px solid rgb(27, 29, 33) !important;
  padding: 0px 12px 0px 12px !important;
}
.backArrow:active {
  position: relative;
  right: 2px;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}
.errmsg {
  color: red;
  font-size: 12px;
}
/* drag & drop */
.labelforinput {
  cursor: pointer;
}
.inputcard {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inputcard img {
  margin-bottom: 10px;
}
.inputcard:hover {
  border-color: #44b1e4;
}
.inputcard {
  transition: border-color 0.3s ease;
}
@media screen and (max-width: 768px) {
  .textContainer {
    width: 70%;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h1 {
    font-size: 25px;
  }
}
@media screen and (max-width: 375px) {
  .textContainer > h1 {
    font-size: 20px;
  }
}
