.inpfield {
  padding: 0 16px 0 12px;
  height: 40px;
  border: 1px solid #dddbda;
  border-radius: 4px !important;
  background-color: #fff;
  color: rgb(8, 7, 7);
}
.inpfield:focus {
  border-color: none !important;
  box-shadow: none !important;
}
.heading {
  text-align: center;
}
.btn {
  background-color: #f5af32 !important;
  border: none !important;
}
@media screen and (max-width: 425px) {
  .heading,
  .formLabel {
    font-size: 14px;
  }
}
