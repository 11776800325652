.orderList > li {
  color: white;
  margin-top: 20px;
}
.btn {
  background: white !important;
  border: none !important;
  color: black !important;
}
.firstContainer {
  /* padding: 35px; */
  padding-left: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoContainer {
  display: flex;
  align-items: center;
}
.videoIframe {
  width: 100%;
  height: 280px !important;
}
@media screen and (max-width: 768px) {
  .firstContainer {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 768px) {
  .videoContainer {
    position: relative;
    right: 62px;
    top: 5px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 984px) {
  .videoIframe {
    width: 350px;
    height: 250px;
  }
}
@media screen and (min-width: 883px) and (max-width: 1054px) {
  .videoIframe {
    width: 400px;
    height: 250px;
  }
}
@media screen and (min-width: 1054px) {
  .videoIframe {
    width: 480px;
    height: 250px;
  }
}
