.textContainer {
  position: absolute;
  margin-left: 45px;
  top: 35%;
  width: 75%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}

.orbiowaycontainer {
  background-image: url("/public/assets/orbioway.webP");
  color: #fff;
  min-height: auto;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .textContainer {
    top: 25%;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 16px;
  }
  .textContainer > h1 {
    font-size: 35px;
  }
  .orbioContent > h3 {
    font-size: 20px;
  }
  .orbioContent > p {
    font-size: 14px;
  }
}
