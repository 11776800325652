.viewMore {
  cursor: pointer;
  color: #007fc7;
  text-decoration: none;
  font-size: 12px;
  font-weight: bolder !important;
}
.ottixlogo {
  object-fit: contain;
  width: 230px;
  height: auto;
}
.ottixhowTitle {
  padding: 18px;
  margin-top: 12px;
  margin-bottom: 20px;
}

/* responsive section*/

@media screen and (max-width: 319px) {
  .ottixlogo {
    width: calc(60vw);
  }
  .ottixhowTitle > h6 {
    font-size: calc(66%);
  }
  .viewMore {
    font-size: calc(66%);
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .ottixhowTitle > h6 {
    font-size: 14px;
  }
  .viewMore {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .singleCircleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .roundedcirclecontainer {
    height: 55px !important;
    width: 55px !important;
  }
  .imgIcons {
    width: 30px !important;
  }
  .iconText {
    font-size: 11px !important;
    text-align: center !important;
  }
}

.roundedcirclecontainer {
  width: 75px; /* Adjust the container width to fit the circular image */
  height: 75px; /* Adjust the container height to fit the circular image */
  border-radius: 50%; /* Make the container circular */
  overflow: hidden; /* Hide overflowing content (image) */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1eded;
}
.imgIcons {
  height: auto;
  width: 40px;
}
.iconText {
  font-size: 12px;
  margin-top: 12px;
  width: 75%;
  text-align: left;
}
.keymetrics {
  font-weight: 600;
  font-size: 28px;
  color: #212529;
}
.desktopImgContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.desktopImg {
  width: 100%;
  height: auto;
  object-fit: contain;
}
