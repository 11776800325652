.reviewsectionImg {
  height: 80px;
  width: 80px;
  cursor: pointer;
  margin-top: 12px;
}
.reviewmainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  transition: transform 0.5s ease-in-out;
}
.reviewSectionname {
  font-size: 14px;
  font-weight: 400;
  color: #007fc7;
  margin-bottom: 2px;
}
.reviewDescrip {
  width: 80%;
  font-size: 15px;
  line-height: 20px;
  margin-top: 25px;
}
.selecteduserName {
  color: #007fc7;
  font-size: 14px;
  display: none;
}
.selectedDesig {
  color: #007fc7;
  font-size: 14px;
}
/* responsive section */

@media (max-width: 768px) {
  .reviewDescrip {
    width: 95%;
    font-size: 14px;
    margin-bottom: 50px;
  }
}
@media (max-width: 475px) {
  .reviewsectionImg {
    height: 47px;
    width: 47px;
  }
  .reviewmainContainer {
    gap: 0px;
  }
  .reviewSectionname {
    font-size: 13px;
  }
}
@media (max-width: 425px) {
  .reviewSectionname {
    display: none;
  }
  .selecteduserName {
    display: block;
  }
}
@media (max-width: 375px) {
  .reviewDescrip {
    font-size: 13px;
  }
  .selecteduserName {
    font-size: 13px;
  }
  .selectedDesig {
    font-size: 13px;
  }
  .reviewmainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    transition: transform 0.5s ease-in-out;
  }
}
@media (max-width: 320px) {
  .reviewsectionImg {
    height: 44px;
    width: 44px;
  }
}
