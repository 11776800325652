@font-face {
  font-family: "ddinBold";
  src: url("../../../src/fonts/D-DIN-Bold.otf") format("truetype");
}
.heading {
  font-family: ddinBold;
}
.boldtext {
  font-weight: bold;
}
.threedee {
  color: white;
  padding: 0.5em;
  transform: perspective(600px) rotateY(-15deg); /* change the rotation of the image */
}
.threedee img {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}
.threedee img {
  width: 100%;
  height: auto;
}
.btn:hover .arrowIcon {
  transform: translateX(5px); /* Move the arrow to the right on hover */
}
.arrowIcon {
  transition: transform 0.3s ease; /* Apply transition on transform property */
}
