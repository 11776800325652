.textContainer {
  position: absolute;
  margin-left: 45px;
  top: 35%;
  /* width: 100%; */
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
