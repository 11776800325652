.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 30%;
  width: 65%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}
@media screen and (max-width: 768px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h1 {
    font-size: 30px;
  }
}
