.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 35%;
  width: 45%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
.btn {
  background-color: #f5af32 !important;
  border: #f5af32 !important;
}
.serviceSecondSection {
  display: none;
}
.ourService:hover {
  background: black;
}
.servicefirstSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.serviceCol {
  padding: 20px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ourService:hover {
  .servicefirstSection {
    display: none;
  }
  .serviceSecondSection {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .service1 {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .service2 {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .service3 {
    border-bottom: 1px solid #ccc;
  }
  .service4 {
    border-right: 1px solid #ccc;
  }
  .service5 {
    border-right: 1px solid #ccc;
  }
}
@media screen and (max-width: 767px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
  .textContainer > h6 {
    font-size: 16px;
  }
  .service1 {
    border: 1px solid #ccc;
  }
  .service2,
  .service3,
  .service4,
  .service5,
  .service6 {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 14px;
  }
  .textContainer > h1 {
    font-size: 35px;
  }
  .contactheading {
    font-size: 15px;
  }
  .btn {
    font-size: 13px;
  }
}
