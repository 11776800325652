.navThirdItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navSecondItem div a {
  text-decoration: none;
  color: white;
}
.dropItem a {
  padding: 12px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.dropItem a:hover {
  background-color: #007fc7;
  color: white;
}
.active {
  color: #007fc7 !important;
  font-weight: bolder;
}
.activeBackground {
  background: #007fc7 !important;
  color: white !important;
}
.whatsappIcon {
  font-size: 40px;
  cursor: pointer;
  color: white;
}
.hamburIcon {
  font-size: 23px;
  cursor: pointer;
  color: white;
}
.img {
  height: auto;
  object-fit: contain;
  width: 125px;
}
.icon {
  color: white;
  font-size: 20px;
}
.socialIconsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.iconsContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}
.navlinkContainer {
  gap: 24px;
  font-size: 17px;
}

.fadeInDropdown {
  animation: fadeIn 0.3s ease-in-out;
}
.navbarMainContainer {
  width: 100%;
  background: linear-gradient(rgb(0 0 0 / 81%), rgb(0 0 0 / 79%)), transparent;
  position: fixed;
  z-index: 999;
  top: 0;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(
      -10px
    ); /* Optional: Start from a slightly higher position */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* responsive section */
@media screen and (max-width: 1075px) {
  .fadeInDropdown {
    max-height: 68vh !important;
    overflow: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .img {
    margin-top: -28px;
  }
}
@media screen and (min-width: 768px) and (max-width: 825px) {
  .navlinkContainer {
    gap: 18px;
    font-size: 14px;
  }
}
@media screen and (min-width: 826px) and (max-width: 892px) {
  .navlinkContainer {
    gap: 20px;
    font-size: 15px;
  }
}
@media screen and (min-width: 893px) and (max-width: 900px) {
  .navlinkContainer {
    gap: 24px;
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .navbarMainContainer {
    height: 70px;
  }
}
@media screen and (min-width: 135px) and (max-width: 250px) {
  .img {
    width: 50px;
  }
  .whatsappIcon {
    font-size: 12px;
  }
  .hamburIcon {
    font-size: 12px;
  }
  .navThirdItem {
    position: relative;
    right: -13px;
  }
  .iconsContainer {
    gap: 3px;
  }
}
@media screen and (min-width: 114px) and (max-width: 135px) {
  .img {
    width: 35px;
  }
  .iconsContainer {
    gap: 3px;
  }
  .whatsappIcon {
    font-size: 12px;
  }
  .hamburIcon {
    font-size: 12px;
  }
  .navThirdItem {
    position: relative;
    right: -21px;
  }
}
@media screen and (max-width: 114px) {
  .img {
    width: 25px;
  }
  .iconsContainer {
    gap: 1px;
  }
  .whatsappIcon {
    font-size: 7px;
  }
  .hamburIcon {
    font-size: 7px;
  }
  /* .navThirdItem{
    position: relative;
    right: -21px;
  } */
}
