@font-face {
  font-family: "ddinBold";
  src: url("../../../src/fonts/D-DIN-Bold.otf") format("truetype");
}
.heading {
  font-family: ddinBold;
  font-size: 35px;
}
.overviewFirstSec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.overviewFirstSec > p {
  font-size: 18px;
}
.overviewdesc {
  font-size: 16px;
  letter-spacing: 1px;
}

@media screen and (max-width: 375px) {
  .heading {
    font-size: 30px;
  }
  .overviewFirstSec > p {
    font-size: 17px;
  }
  .overviewdesc {
    font-size: 15px;
  }
}
