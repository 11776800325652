/* aboutSlider.module.css */

/* Existing styles for left side */
.stickyContainerLeft {
  position: sticky;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.stickyShadow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0 0 0 / 70%), rgb(0 0 0 / 70%));
  z-index: -1;
}

.firstimgContent {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

/* New styles for individual images on the right side */
.rightSideImageContainer {
  display: flex;
  flex-direction: column;
}

.imageWithShadow {
  position: relative;
  overflow: hidden;
}

.rightSideImage {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
}

.imageWithShadow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0), rgb(0, 0, 0));
  z-index: 1;
  opacity: 0.6; /* Adjust the opacity of the shadow */
}
.centeredText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  margin-left: 5px;
  overflow-y: auto;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
/* font styles in right side images */
.imageWithShadow span {
  min-width: 100%;
}
.centeredText h2 {
  font-weight: 800;
  font-size: 30px;
  color: #fefefe;
}
.centeredText p {
  font-weight: 400;
  font-size: 16px;
  color: white;
}
.firstimgContent h2 {
  font-weight: 800;
  font-size: 30px;
  color: #fefefe;
}
.firstimgContent p {
  font-weight: 400;
  font-size: 16px;
  color: #fefefe;
}
