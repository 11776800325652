.crossIcon {
  font-size: 20px;
  margin-top: -5px;
}
.pointerCursor {
  cursor: pointer;
}
.closeBtn {
  background: #ededed;
  padding: 6px;
  border-radius: 7px;
}
.navlinks {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}
.navlinks a {
  text-decoration: none;
  color: black;
  font-size: 18px;
}
.navlinks a:hover {
  color: blue;
}
.active {
  color: blue !important;
  font-weight: 500;
}
.serviceSubItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin: 12px; */
}
.serviceSubItems > :first-child {
  margin-top: 10px;
}
.serviceSubItems a {
  font-size: 15px;
}
.serviceSubItems a:hover {
  color: blue;
}
.iconBorder {
  border: 1px solid #efe5e5;
  padding: 1px;
  width: 24px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* animation section of serviceSubItems */
.serviceSubItems {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out,
    visibility 0s 0.3s;
}

.showSubItems {
  max-height: 500px; /* Set the maximum height for smooth expansion */
  opacity: 1;
  visibility: visible;
  transition: max-height 0.5s ease-in, opacity 0.3s ease-out, visibility 0s 0s;
}
