.heading {
  font-weight: bolder;
}
.desc {
  font-size: 14px;
  font-weight: bold;
}
.btnContainer {
  display: flex;
  gap: 12px;
}
.mainContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #acd4eb;
  height: auto;
  width: 100%;
}
@media screen and (max-width: 425px){
    .heading{
        font-size: 15px;
    }
    .desc{
        font-size: 12px;
    }
}