.heading {
  font-size: 28px;
  font-weight: 800;
  color: #1b1d21;
  text-align: center;
}
.carouselRow > div > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.img {
  width: 100%;
  margin: 25px;
  height: auto;
  object-fit: scale-down;
  filter: grayscale(93%);
}
.img:hover {
  filter: grayscale(0%);
}
@media (max-width: 375px) {
  .heading {
    font-size: 24px;
  }
}
.redUnderline {
  position: relative;
  display: inline-block;
}

/* Underline effect */
.redUnderline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  background-color: #007fc7;
}
