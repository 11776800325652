/* card hover effect */
.cardContainer {
  cursor: pointer;
}

.card {
  position: relative !important;
  border: none !important;
  height: 260px !important;
}

.cardContainer {
  overflow: hidden;
}
.cardTitle {
  color: #0baad8;
  text-align: left;
}
.cardContainer .cardSection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(63, 62, 62, 0.6); /* Semi-transparent background */
  backdrop-filter: blur(0.2px); /* Apply a smaller blur */
  color: whitesmoke;
  border-radius: 8px;
  height: 100%;
  padding: 20px;
  transform: translateY(65%);
  transition: transform 0.3s ease;
}
.cardImg {
  filter: grayscale(100%) !important;
  height: 260px !important;
  width: 100% !important;
  object-fit: cover !important;
}
.cardContainer:hover .cardSection {
  transform: translateY(0);
}

.cardSection > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 425px) {
  .cardSection > div > p {
    font-size: 14px;
    overflow: scroll;
  }
  .cardTitle {
    font-size: 18px !important;
  }
}
