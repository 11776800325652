.aboutCeoMainContainer {
  background: darkgray;
  min-height: 50vh;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 40px;
}

.aboutCeoCard {
  background-color: #efefef !important;
  border: none !important;
  padding: 20px !important;
}
.aboutCeoImg {
  height: 300px !important;
  object-fit: contain !important;
}
@media screen and (min-width: 768px) {
  .ceocontentsection > p {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  .aboutCeoImg {
    height: auto !important;
    margin-bottom: 12px;
  }
}
