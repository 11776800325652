.cardContainer {
  position: relative;
  margin-bottom: 10px;
  padding: 4px 4px 0px 0px;
}
.cardtopborder {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 40%;
  width: 20%;
  border-right: 5px solid #007fc7;
  border-top: 5px solid #007fc7;
  z-index: 1;
}
.card {
  padding: 20px 0px 10px 10px;
  border: 1px solid #ccc;
  background-color: #f2f2f2;
  border-radius: 0px !important;
  display: flex;
  justify-content: center;
}
.cardContent {
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  padding-right: 15px;
}
.cardContent > h4 {
  text-align: start;
  font-weight: bold;
  font-size: 16px;
}
.cardContent > p {
  font-size: 13px;
}
