.heading {
  font-size: 28px;
  padding-bottom: 10px;
}
.mainlist > li {
  font-size: 16px;
}
.aligncenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img {
  height: auto;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .heading {
    font-size: 25px;
  }
  .mainlist > li {
    font-size: 14px;
  }
  .aligncenter {
    display: flex;
    flex-direction: column-reverse;
    gap: 25px;
  }
}
