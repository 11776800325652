.cardContainer {
  cursor: pointer;
  margin-top: 20px;
}
.btn {
  color: black !important;
  background: none !important;
  border-color: black !important;
  padding: 3px !important;
  font-size: 10px !important;
}
.card {
  position: relative !important;
  border: none !important;
  height: 200px !important;
}
.keywordcontainer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  position: relative;
  top: 35%;
}
@media screen and (max-width: 768px) {
  .cardContainer {
    margin-bottom: 20px;
  }
}
.cardContainer {
  overflow: hidden;
}
.blogtitle {
  color: #0baad8;
  font-size: 14px !important;
  font-weight: 600;
  text-align: left;
}
.cardContainer .cardSection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  margin-left: 11px;
  border-radius: 10px 0px 0px 0px;
  height: 83%;
  text-align: center;
  padding: 8px;
  transform: translateY(65%);
  transition: transform 0.3s ease;
}

.cardSection h6 {
  font-size: 14px;
  margin: 0;
}

.cardContainer:hover .cardSection {
  transform: translateY(0);
}
