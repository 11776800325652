.redUnderline {
  position: relative;
  display: inline-block;
}
.mainheading{
  font-weight: bold;
  text-align: center;
}
/* Underline effect */
.redUnderline::after {
  content: "";
  position: absolute;
  left: 10px;
  bottom: -2px;
  width: 72%;
  height: 3px;
  background-color: #007fc7;
}
.singlecard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none !important;
}
.cardcontent {
  font-size: 16px;
  margin-bottom: 0px;
}
/* responsive */
@media (max-width: 767px) {
  .singlecard {
    margin-top: 12px;
  }
}
@media (max-width: 425px) {
  .cardcontent {
    margin-top: 14px;
  }
}

@media screen and (max-width: 319px) {
  .mainheading {
    font-size: calc(6vw);
  }
}
