.backgroundImageRow {
  background-image: url("../../../public/assets/servicebackground.WebP");
  background-size: cover;
  background-position: center;
  position: sticky;
  min-height: 90vh;
}

.heading {
  font-size: 19px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0.3px;
  color: #fefefe;
  padding-top: 2em;
  padding-bottom: 25px;
  text-align: start;
}

.desc {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.3px;
  color: #d4d4d4;
  text-align: start;
}

.columnDivider {
  border-right: 2px solid white;
  margin: 0 auto;
  text-align: center;
  color: white;
  display: block;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 100%;
}

.columnDivider:last-child {
  border-right: none;
}

.columnDivider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: white;
  transition: height 0.4s ease-in-out;
  z-index: -1;
}

.mainColumn {
  height: 90vh;
  border-right: 1px solid #796e6e;
}
.mainColumn:last-child {
  border-right: none;
}
.btncontainer {
  display: none;
}
.viewbtn {
  position: absolute;
  bottom: 0%;
  border: none !important;
  background: none !important;
  color: white;
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: 12% !important;
}
.viewbtn:hover {
  color: #007fc7 !important;
}
.mainColumn:hover .btncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mainColumn:hover .columnDivider {
  border-right: none;
  color: #54595f;
}

.mainColumn:hover .columnDivider .heading {
  font-size: 19px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0.3px;
  color: #54595f;
  padding-top: 2em;
  padding-bottom: 25px;
}

.mainColumn:hover .columnDivider .desc {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.3px;
  color: #54595f;
}

.mainColumn:hover .columnDivider::before {
  height: 100%;
}
@media (min-width: 768px) {
  .viewbtn {
    margin-bottom: 25%;
  }
  .overflowScroll {
    overflow-y: auto;
    max-height: 55vh;
  }
  .overflowScroll::-webkit-scrollbar {
    width: 0; /* Set the width of the scrollbar to 0 */
  }

  .overflowScroll::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set the color of the scrollbar thumb to transparent */
  }

  .overflowScroll::-webkit-scrollbar-track {
    background-color: transparent; /* Set the color of the scrollbar track to transparent */
  }
}
@media (max-width: 767px) {
  .columnDivider {
    border-right: none;
    height: auto;
    margin-bottom: 20px;
  }
  .btncontainer {
    margin-top: 10px;
  }
  .mainColumn {
    height: 100%;
    border-right: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .heading {
    padding-top: 15px;
    padding-bottom: 12px;
    font-size: 16px;
  }
  .desc {
    font-size: 14px;
    line-height: 22px;
  }
  .mainColumn:hover .columnDivider .desc {
    font-size: 14px;
    line-height: 22px;
  }
  .mainColumn:hover .columnDivider .heading {
    padding-top: 15px;
    padding-bottom: 12px;
    font-size: 16px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1024px) {
  .viewbtn {
    margin-bottom: 2% !important;
  }
}
@media (min-width: 1400px) {
  .viewbtn {
    margin-bottom: 0%;
  }
}
@media (max-width: 425px) {
  .backgroundImageRow {
    background-image: none;
    background-color: black;
  }
}
