@font-face {
  font-family: "ddinBold";
  src: url("../../../src/fonts/D-DIN-Bold.otf") format("truetype");
}
.headingText {
  font-family: ddinBold;
}
.iconContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 30px;
}
.link {
  font-size: 20px;
  color: #6d6d6d;
}
.iconTextContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.formHeadingText {
  font-family: ddinBold;
  font-size: 36px;
}
.descText {
  font-size: 20px;
}
