.heading {
  color: white;
  font-size: 53px;
  font-weight: bold;
}
.subheading {
  margin-top: 17px;
  color: #c7c2c2;
}
@media screen and (max-width: 768px) {
  .heading {
    font-size: 34px;
  }
}
@media screen and (max-width: 320px) {
  .heading {
    font-size: 28px;
  }
  .subheading {
    font-size: 16px;
  }
}
