.textContainer {
  position: absolute;
  margin-left: 45px;
  top: 35%;
  /* width: 100%; */
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.4; /* Adjust the opacity as needed */
  max-height: 80vh;
}
