.mainContainer {
  background-color: #d3d2d2;
  padding: 15px;
  position: relative;
  z-index: 1;
}
.heading {
  font-weight: bolder;
  color: black;
  text-align: center;
}
.img {
  width: 100%;
  /* margin: 25px; */
  height: 90px;
  cursor: pointer;
  object-fit: scale-down;
  filter: grayscale(93%);
}
.img:hover {
  filter: grayscale(0%);
}
.custom-carousel-item {
  margin-right: 0px; /* Adjust the margin as needed to decrease the gap */
}
@media screen and (max-width: 375px){
  .heading{
    font-size: 18px;
  }
}