.icon {
  color: #007fc7;
  font-size: 27px;
  font-weight: 400;
  cursor: pointer;
  border: 12px;
}
.icon:hover {
  background-color: #007fc7;
  color: white;
  border-color: transparent;
}
.findheading {
  color: rgb(109, 109, 109);
  font-size: 15px;
  font-weight: 400;
}
.subLink {
  font-size: 15px;
  text-decoration: none;
  color: black;
}
.subLink:hover {
  color: #43baff;
  cursor: pointer;
}

.active {
  font-size: 15px;
  color: #007fc7 !important;
  font-weight: 600;
  text-decoration: none;
}

.service,
.company,
.reachus {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
@media (max-width: 767px) {
  .service {
    margin-top: 25px;
  }
  .company {
    margin-top: 25px;
  }
  .reachus {
    margin-top: 25px;
  }
  .findus {
    margin-top: 25px;
  }
}
