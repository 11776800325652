.inpLabel {
  font-size: 12px;
}
.inpfield {
  padding: 0 16px 0 12px;
  height: 32px;
  border: 1px solid #dddbda;
  border-radius: 4px;
  background-color: #fff;
  color: rgb(8, 7, 7);
}
.inpfield:focus {
  border-color: none !important;
  box-shadow: none !important;
}
