.heading {
  font-size: 28px;
  padding-bottom: 15px;
  color: black !important;
}
.aboutimg {
  height: 190px;
  width: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}
.title {
  color: black !important;
  font-weight: bold !important;
  font-size: 18px !important;
}
.desc {
  font-size: 15px;
  padding-top: 10px;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 25px;
  }
}
