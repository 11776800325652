.textContainer {
  position: absolute;
  padding-left: 45px;
  top: 35%;
  width: 45%;
}
.textContainer > h1 {
  font-weight: bolder;
  font-size: 45px;
  color: whitesmoke;
}
.textContainer > h6 {
  font-size: 19px;
  color: whitesmoke;
}
@media screen and (max-width: 768px) {
  .heading {
    font-size: 34px;
  }
  .textContainer {
    width: 75%;
  }
  .textContainer > h1 {
    font-size: 40px;
  }
  .textContainer > h6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 425px) {
  .textContainer > h6 {
    font-size: 14px;
  }
  .textContainer > h1 {
    font-size: 35px;
  }
}
